import tableGetters from "@/store/templates/table/getters";

export default {
  ...tableGetters,
  filterCategories: state => filterId =>
    state.entity[filterId].filterCategories.map(
      filterCategory => filterCategory.category
    ),
  country: state => filterId => state.country[state.entity[filterId].country],
  categories: (state, getters) => filterId =>
    getters
      .filterCategories(filterId)
      .map(categoryId => state.category[categoryId]),
  deliveryMethods: state => filterId =>
    state.entity[filterId].deliveryMethods.map(id => state.deliveryMethod[id]),
  deliveryMethodContactPersons: (
    state,
    getters,
    rootState
  ) => deliveryMethodId =>
    state.deliveryMethod[deliveryMethodId].recipients.map(
      id => rootState.company.contactPerson[id]
    )
};
